require('./bootstrap');
window.currentUrl = `${location.origin}${location.pathname}`;
feather.replace();

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

$('.lightbox, a[data-lightbox]')
    .magnificPopup({
        type: 'image'
    });
$('.lightbox.gallery, [data-lightbox="gallery"]')
    .magnificPopup({
        type: 'image',
        gallery: {
            enabled: true
        }
    });

$('footer a[href="javascript:;"].dd-remove')
    .remove();

$('#flash-overlay-modal').modal();

// welcome page

function makePostCode(objs) {
    const html = [];
    if (objs.length && objs.length > 0) {
        for (const obj of objs) {
            const {postLink, date, title} = obj;
            const code = `<div class="news-letter-item">
                            <a href="${postLink}">
                                <span class="date mr-2"><i data-feather="calendar"></i> ${date}</span>
                                <span class="title">${title}</span>
                            </a>
                        </div>`;
            html.push(code);
        }
    }

    return html.join('\n');
}

async function fetchLatestPosts(categoryName) {
    const endpoint = '/api/fetch-posts-by-categories';
    const container = document.querySelector('#news-wrapper');

    if (container) {
        try {
            const res = await axios.get(endpoint, {
                params: {
                    category: categoryName
                }
            });

            const html = makePostCode(res.data);
            container.innerHTML = html;
            feather.replace();

            $(container)
                .slick({
                    autoplay: true,
                    autoplaySpeed: 1500,
                    dots: true,
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    arrows: false,
                    vertical: true,
                    verticalSwiping: true
                });

        } catch (err) {
            console.log(err.response ? err.response : (err.request ? err.request : err));
            return null;
        }
    }
}

const subscriptionForm = document.getElementById('news-subscription-form');
if (subscriptionForm) {
    subscriptionForm.addEventListener('submit', async e => {

        e.preventDefault();

        const msgContainer = document.getElementById('subscription-apply-result');

        try {
            const fd = new FormData(e.target);
            const res = await axios.post('/subscription', fd);

            msgContainer.classList.add('text-success');
            msgContainer.innerText = res.data.message;
            showAlert('성공', res.data.message);

            setTimeout(() => {
                e.target.reset();
                msgContainer.innerText = '';
            }, 3000);

        } catch (err) {
            msgContainer.classList.add('text-danger');
            msgContainer.innerHTML = '';

            if (err.response && err.status === 419) {
                if (err.response.errors.email) {
                    // eslint-disable-next-line prefer-destructuring
                    msgContainer.innerText = err.response.errors.email[0];
                }
                if (err.response.errors.name) {
                    msgContainer.innerHTML = `${msgContainer.innerText}<br>${err.response.errors.name[0]}`;
                }
            } else {
                msgContainer.innerText = 'Error';
            }
        }
    });
}

document.addEventListener('DOMContentLoaded', async e => {
    await fetchLatestPosts('교회소식');
});

// admin post show image gallery
const imgs = document.querySelectorAll('.post-content img');
if (imgs && imgs.length) {
    imgs.forEach(el => {
        if (el.parentNode.nodeName !== 'A') {
            const link = document.createElement('a');
            link.dataset.lightbox = 'gallery';
            link.href = el.src;
            el.parentNode.prepend(link);
            link.appendChild(el);
            $(link)
                .magnificPopup({
                    type: 'image',
                    gallery: {
                        enabled: true
                    }
                });
        }
    });
}

// checkall
function syncCheckbox(obj) {
    const checkboxes = document.querySelectorAll('table tbody input[type="checkbox"]');
    if (checkboxes && checkboxes.length > 0) {
        for (const item of checkboxes) {
            item.checked = obj.checked;
        }
    }
}

const checkAll = document.getElementById('checkall');
if (checkAll) {
    checkAll.addEventListener('click', (e) => {
        syncCheckbox(checkAll);
    });
}

// toast
window.showAlert = (title, message, delaytime = null) => {
    const toast = document.querySelector('#toast-tpl').content.firstElementChild.cloneNode(true);
    const id = `toast-${moment().format('x')}`;
    toast.id = id;
    toast.querySelector('.toast-header strong').textContent = title;
    toast.querySelector('.toast-header small').textContent = moment().format('h:mm:ss a');
    toast.querySelector('.toast-body').innerHTML = message;

    switch (title) {
        case '오류':
        case 'error':
        case 'danger':
            toast.querySelector('.toast-header')
                .classList
                .add('bg-danger', 'text-white');
            break;
        case '성공':
        case '완료':
        case 'OK':
        case 'success':
            toast.querySelector('.toast-header')
                .classList
                .add('bg-success', 'text-white');
            break;
        case 'primary':
            toast.querySelector('.toast-header')
                .classList
                .add('bg-primary', 'text-white');
            break;
        case 'info':
        default:
            toast.querySelector('.toast-header')
                .classList
                .add('bg-info', 'text-white');
    }

    if (delaytime && delaytime > 0) {
        $(toast)
            .toast({
                autohide: true,
                delay: delaytime * 1000
            });
        setTimeout(() => {
            document.getElementById(id)
                .remove();
        }, (parseInt(delaytime) * 1000) + 500);
    } else {
        $(toast)
            .toast({
                autohide: false,
                delay: 500
            });
    }

    const tg = document.querySelector('body');
    if (tg.querySelectorAll('.toast').length > 0) {
        const relTg = tg.querySelectorAll('.toast')[tg.querySelectorAll('.toast').length - 1];
        if (relTg && !relTg.classList.contains('hide')) {
            const posY = relTg.getClientRects()[0].top + relTg.getClientRects()[0].height + 10;
            toast.style.top = `${posY}px`;
        }
    }

    tg.appendChild(toast);

    feather.replace();
    $('.toast').toast('show');
};

const sidemenuLinks = document.querySelectorAll('#submenu .nav-link');
if (sidemenuLinks && sidemenuLinks.length > 0) {
    for (const item of sidemenuLinks) {
        if (item.href === currentUrl) {
            item.classList.add('active');
        }
    }
}

const showSidemenus = document.querySelector('a[data-show-sidemenu]'); // admin sidemenu
if (showSidemenus) {
    showSidemenus.addEventListener('click', function (e) {
        const sidemenu = document.querySelector('aside');
        const pos = sidemenu.getBoundingClientRect();
        if (pos.x < 0) {
            this.textContent = '닫기';
            anime({
                targets: sidemenu,
                duration: 300,
                left: 0,
                easing: 'easeInOutExpo'
            });

        } else {
            this.textContent = '메뉴';
            anime({
                targets: sidemenu,
                duration: 300,
                left: -pos.width,
                easing: 'easeInOutExpo'
            });
        }
    });

}

// 네비게이션 active

function showMenu(el) {
    mainNavInit();
    if (el.closest('.nav-depth-0')) {
        el.closest('.nav-depth-0')
            .classList
            .add('show');
    }
    !el.classList.contains('show')
        ? el.classList.add('show')
        : '';
}

function hideMenu(el) {
    el.classList.remove('show');
    el.classList.remove('selected');
}

function toggleMenu() {
    const nav = document.getElementById('main-navigation');
    nav.classList.toggle('show');
}

function mainNavInit() {
    const mainNav = document.getElementById('main-navigation');
    if (mainNav) {
        const visibleMenus = mainNav.querySelectorAll('.show, .selected');
        if (visibleMenus && visibleMenus.length > 0) {
            for (const vm of visibleMenus) {
                hideMenu(vm);
            }
        }
    }
}

const mainNavToggler = document.getElementById('mobile-nav-toggler');
if (mainNavToggler) {
    mainNavToggler.onclick = () => {
        mainNavToggler.classList.toggle('is-active');
        toggleMenu();
    };
}

const mainNav = document.getElementById('main-navigation');
if (mainNav) {
    const elems = mainNav.querySelectorAll('nav[data-nav-name="main"] > .nav-item');
    for (const el of elems) {
        const subnav = el.querySelector('.nav-child');
        if (subnav) {
            el.addEventListener('mouseover', (e) => {
                showMenu(el);
                const subnavItems = subnav.querySelectorAll('.nav-item');
                for (const submenu of subnavItems) {
                    submenu.addEventListener('mouseover', evt => {
                        showMenu(evt.target);
                    });
                }
            });
        }
    }

    const mainNavLinks = mainNav.querySelectorAll('.nav-link');
    const mainNavLinkHandler = (e) => {
        e.preventDefault();
        mainNavInit();

        const link = e.currentTarget;
        const menu = e.currentTarget.closest('.nav-item');

        menu.classList.toggle('selected'); // 현재 선택 여부 표시

        if (!menu.querySelector('.nav-child')) {
            if (!menu.classList.contains('nav-search') || !menu.classList.contains('nav-main-menu-toggle')) {
                location.href = link.href;
            }
        } else {
            menu.classList.add('show');
            if (menu.closest('.nav-child')) {
                showMenu(menu.closest('.nav-item'));
            }
        }
    };

    for (const link of mainNavLinks) {
        link.href === currentUrl
            ? link.classList.add('active')
            : '';

        link.addEventListener('click', e => {
            mainNavLinkHandler(e);
        });
    }

    // 윈도우 스크롤 시 메인 네이게이션 위치 조정
    window.addEventListener('scroll', (e) => {
        const mainNav = document.getElementById('main-nav-wrap');
        const megaMenu = document.querySelector('#main-nav-mega');
        const headerHeight = document.body.querySelector('header').clientHeight;
        if (window.outerWidth > 480) {
            if (window.scrollY > headerHeight) {
                mainNav.classList.add('on-top', 'animated', 'fadeInDown');
            } else {
                mainNav.classList.remove('on-top', 'animated', 'fadeInDown');
            }
        }
    });

    if (mainNavLinks && mainNavLinks.length > 0) {
        for (const link of mainNavLinks) {
            if (decodeURIComponent(link.href) === decodeURIComponent(location.href)) {
                if (link.closest('.nav-depth-0')) {
                    link.closest('.nav-depth-0')
                        .classList
                        .add('active');
                } else if (link.closest('.nav-item')) {
                    link.closest('.nav-item')
                        .classList
                        .contains('nav-depth-0')
                        ? link.closest('.nav-item')
                            .classList
                            .add('active')
                        : '';
                }
            }
        }
    }
}

document.body.addEventListener('click', (e) => {
    if (!e.target.closest('#main-navigation')) {
        mainNavInit();
    }
});

window.toggleSearch = () => {
    const searchZone = document.getElementById('search-section');
    searchZone.style.display = searchZone.style.display === 'none' ? '' : 'none';
    if (searchZone.style.display !== 'none') {
        searchZone.querySelector('input[name="search"]')
            .focus();
    }
};

window.toggleMainMenu = () => {
    const caller = document.querySelector('#main-nav-wrap');
    const menu = document.getElementById('main-nav-mega');
    menu.style.display = menu.style.display === 'none'
        ? ''
        : 'none';

    if (menu.style.display !== 'none') {
        if (caller.classList.contains('on-top')) {
            menu.style.top = '60px';
        }
    }

    window.addEventListener('scroll', e => {
        if (window.outerWidth > 480) {
            menu.style.top = (200 - window.scrollY) + 'px';
        }
    })
};

const mainMegamenuDepth1Links = document.querySelectorAll('#main-nav-mega .nav-depth-0 > .nav-link');
if (mainMegamenuDepth1Links) {
    for (const item of mainMegamenuDepth1Links) {
        item.addEventListener('click', e => {
            e.preventDefault();
        });
    }
}

// 사이드메뉴
const sidemenus = document.querySelectorAll('#front-sidemenu .nav-link.active');
if (sidemenus.length) {
    for (const menu of sidemenus) {
        if (decodeURIComponent(menu.href) === decodeURIComponent(location.href)) {
            const collapseMenu = menu.closest('.collapse');
            if (collapseMenu) {
                $(collapseMenu)
                    .collapse('show');
            }
        }
    }
}

// go top 버튼
const goTopBtn = document.getElementById('go-top');
if (goTopBtn) {
    goTopBtn.addEventListener('click', () => {
        window.scrollTo(0, 0);
    });
}

// 관리자모드 네비게이션
const adminSideMenu = document.getElementById('adminSideMenu');
if (adminSideMenu) {
    const menulinks = adminSideMenu.querySelectorAll('.nav-link');
    if (menulinks.length) {
        let url = `$location.origin}${location.pathname}`;
        if (url !== location.href) {
            url = location.href;
        }
        for (const link of menulinks) {
            if (link.href === url) {
                link.classList.add('active');
                if (link.closest('.collapse')) {
                    $(link.closest('.collapse'))
                        .collapse('show');
                }
            }
        }
    }
}

$('#flash-overlay-modal')
    .modal();
$('[data-toggle="tooltip"]')
    .tooltip();
$('div.alert')
    .not('.alert-important')
    .delay(3000)
    .fadeOut(350);

// AnimateCss 함수 확장
$.fn.extend({
    animateCss(animationName, callback) {
        const animationEnd = (function (el) {
            const animations = {
                animation: 'animationend',
                OAnimation: 'oAnimationEnd',
                MozAnimation: 'mozAnimationEnd',
                WebkitAnimation: 'webkitAnimationEnd'
            };

            for (const t in animations) {
                if (el.style[t] !== undefined) {
                    return animations[t];
                }
            }
        }(document.createElement('div')));

        this.addClass(`animated ${animationName}`)
            .one(animationEnd, function () {
                $(this)
                    .removeClass(`animated ${animationName}`);

                if (typeof callback === 'function') callback();
            });

        return this;
    }
});

window.trimRight = (str) => str.replace(/\s*$/g, '');

// 뒤의 공백 문자열을 제거
window.trimLeft = (str) => str.replace(/^\s*/g, '');

// 앞뒤 공백문자열을 제거
window.trim = (str) => str.replace(/(^\s*)|(\s*$)/gi, '');

const tinymceConfig = {
    relative_urls: false,
    selector: 'textarea#content:not(.not-editor)',
    schema: 'html5',
    language: 'ko_KR',
    menubar: false,
    statusbar: true,
    resize: true,
    height: 500,
    allow_script_urls: true,
    allow_html_in_named_anchor: true,
    valid_elements: '*[*]',
    entity_encoding: 'raw',
    extended_valid_elements: 'script[src|type|language], i, svg',
    plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
    toolbar: ['styleselect forecolor backcolor indent outdent bold italic blockquote removeformat alignleft alignjustify aligncenter alignright bullist numlist media link table code'],
    quickbars_insert_toolbar: 'quicktable quicklink',
    quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 h4 blockquote',
    branding: false,
    content_css: '/assets/css/app.css',
    content_style: 'body{padding:10px;box-shadow:0;font-size:1rem;background-color:white;}'
};

tinymce.init(tinymceConfig);

// 미디어 라이브러리 컨트롤
window.loadLibrary = async (pageNo = 1) => {
    const target = document.querySelector('#media-items .row');

    if (target.querySelector('.card-img-top')) {
        target.innerHTML = '<div class="col"><img src="/images/loading50.gif" alt="loading..." class="d-flex mx-auto my-5"></div>';
    }

    try {
        const response = await axios.get(`/admin/media-library?page=${pageNo}`);
        const {data} = response;
        const {prev_page_url, next_page_url, current_page, from, to} = data;
        const code = document.createDocumentFragment();

        for (const el of data.data) {
            const {
                id, filename, mimetype, size, path, url
            } = el;
            const tag = document.querySelector('#media-library-card-tpl')
                .content
                .cloneNode(true);
            const card = tag.querySelector('.card');
            const thumbnailWrapper = card.querySelector('.img-wrapper');
            const thumbnail = thumbnailWrapper.querySelector('img.thumbnail');
            const fileIcon = thumbnailWrapper.querySelector('.file-icon');
            if (mimetype.split('/')[0] === 'image') {
                thumbnail.alt = filename;
                thumbnailWrapper.style.backgroundImage = `url(${decodeURIComponent(el.url)})`;
                fileIcon.remove();
            } else {
                thumbnail.remove();
            }

            const cardBody = card.querySelector('.card-body');
            const filenameTag = cardBody.querySelector('.filename');
            filenameTag.innerHTML = `${filename}(${FileSize(size, true)})`;

            const addToBody = cardBody.querySelector('.addMedia');
            const mime = mimetype.split('/')[0];
            addToBody.dataset.id = id;
            addToBody.dataset.path = path;
            addToBody.dataset.mimetype = mime;
            addToBody.dataset.filename = filename;
            addToBody.dataset.url = url;

            const addFeaturedImage = cardBody.querySelector('.addFeaturedImage');
            addFeaturedImage.dataset.id = id;
            addFeaturedImage.dataset.path = path;
            addFeaturedImage.dataset.mimetype = mime;
            addFeaturedImage.dataset.filename = filename;
            addFeaturedImage.dataset.url = url;
            addFeaturedImage.onclick = (e) => {
                const tg = document.getElementById('featuredPreview');
                tg.innerHTML = `<img src="${decodeURIComponent(el.url)}" class="w-100 my-1">`;
                document.getElementById('featuredImage').value = path;
                document.getElementById('mediaLibWindow').style.display = 'none';
            };

            code.appendChild(tag);
        }

        const paginate = document.querySelector('#media-library-paginate-tpl')
            .content
            .cloneNode(true);
        const prevLink = paginate.querySelector('.prev');
        const nextLink = paginate.querySelector('.next');

        if (prev_page_url) {
            const prevPageNo = current_page - 1;
            prevLink.setAttribute('onclick', `loadLibrary('${prevPageNo}')`);
            prevLink.style.display = '';
        }
        if (next_page_url) {
            const nextPageNo = current_page + 1;
            nextLink.setAttribute('onclick', `loadLibrary('${nextPageNo}')`);
            nextLink.style.display = '';
        }

        target.innerHTML = '';
        target.appendChild(code);
        target.appendChild(paginate);
        feather.replace();

    } catch (err) {
        console.log(err.response ? err.response : (err.request ? err.request : err));
        showAlert('오류', `오류 발생 : ${err.response && err.response.data.message ? err.response.data.message : err.message}`);
    }
};

// 업로드 트리거
$('#uploadTrigger, #featuredImageUploadTrigger')
    .click(() => {
        $('#fileuploadzone')
            .fadeIn();
    });

// 업로드 닫기
$('#closeUploadzone')
    .click(() => {
        $('#fileuploadzone')
            .fadeOut();
    });

// 미디어 라이브러리 트리거
const mediaLibraryTriggers = document.querySelectorAll('button[data-action="media-library-trigger"]');
if (mediaLibraryTriggers.length > 0) {
    mediaLibraryTriggers.forEach(trigger => {
        trigger.addEventListener('click', e => {
            const target = document.getElementById('mediaLibWindow');
            const items = target.querySelectorAll('.item');
            if (!items || items.length === 0) {
                loadLibrary();
            }
            target.style.display = (target.style.display === 'none') ? '' : 'none';
        });
    });
}

// 미디어 라이브러리 닫기 버튼
const mediaLibraryCloser = document.getElementById('closeMediaLibrary');
if (mediaLibraryCloser) {
    mediaLibraryCloser.addEventListener('click', e => {
        const w = document.getElementById('mediaLibWindow');
        $(w)
            .fadeOut();
    });
}

// 미디어 라이브러리 : 미디어 본문에 추가
$(document)
    .on('click', '.addMedia', (e) => {
        e.preventDefault();
        const parent = document.getElementById('mediaLibWindow');
        const {filename, mimetype, url} = e.target.dataset;
        const code = document.createDocumentFragment();

        if (mimetype === 'image') {
            const img = document.createElement('img');
            img.src = decodeURIComponent(url);
            img.classList.add('img-fluid', 'm-1');
            code.appendChild(img);
        } else {
            const link = document.createElement('a');
            link.href = decodeURIComponent(url);
            link.innerHTML = `<i data-feather="file"></i> ${filename}`;
            code.appendChild(link);
        }

        tinyMCE.execCommand('mceInsertContent', false, code.firstElementChild.outerHTML);
        $(parent).fadeOut();
    });

window.isImage = (mimetype) => {
    const type = mimetype.split('/');
    return type[0] === 'image';
};

window.deleteFile = async (parent, id) => {
    try {
        const data = {
            _token: csrfToken,
            _method: 'delete'
        };
        const response = await axios.post(`/admin/uploads/destroy/${id}`, data);
        if (response.status === 200) {
            parent.classList.add('animated', 'fadeOutDown');
            setTimeout(() => {
                parent.remove();
            }, 500);
        }
    } catch (err) {
        if (err.response && err.response.status === 404) {
            alert('파일 삭제 오류');
        } else {
            alert('통신 오류');
        }
        err.response ? err.response : (err.request ? err.request : err);
    }
};


// Dropzone setting
const previewNode = document.querySelector('#file-row-tpl');
if (previewNode) {
    previewNode.parentElement.style.display = '';
    previewNode.id = '';
    const previewTemplate = previewNode.parentNode.innerHTML;
    previewNode.parentNode.removeChild(previewNode);
    const token = document.head.querySelector('meta[name="csrf-token"]').content;

    let uploadZone = document.querySelector('#fileuploadzone');
    if (!uploadZone) {
        uploadZone = document.body;
    }

    const myDropzone = new Dropzone(uploadZone, {
        url: '/admin/uploads/store', // Set the url
        paramName: 'attachedImage',
        params: {
            _token: token
        },
        acceptedFiles: 'image/*,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx,.hwp,.txt,.zip, .svg, .hwpx, .zip, .7z, .alz, .rar',
        thumbnailMethod: 'contain',
        thumbnailWidth: 200,
        thumbnailHeight: 150,
        parallelUploads: 20,
        previewTemplate,
        autoQueue: true,
        previewsContainer: '#previews',
        clickable: '.uploadTrigger'
    });

    myDropzone.on('success', (file, responseText) => {
        const res = responseText;
        const self = document.createDocumentFragment()
            .appendChild(file.previewElement);
        self.querySelector('.progress').style.display = 'none';
        self.querySelector('button.delete')
            .setAttribute('data-file-id', res.id);
        if (self.querySelector('button.append')) {
            const appendBtn = self.querySelector('button.append');
            appendBtn.setAttribute('data-file-id', res.id);
            appendBtn.setAttribute('data-file-url', res.url);
            appendBtn.setAttribute('data-file-mime', res.mimetype);
            appendBtn.setAttribute('data-file-name', res.filename);
        }
        if (self.querySelector('button.appendFeatured')) {
            const appendFeaturedImgBtn = self.querySelector('button.appendFeatured');
            appendFeaturedImgBtn.setAttribute('data-file-id', res.id);
            appendFeaturedImgBtn.setAttribute('data-file-url', res.url);
            appendFeaturedImgBtn.setAttribute('data-file-mime', res.mimetype);
            appendFeaturedImgBtn.setAttribute('data-file-name', res.filename);
        }

        const mediaWindow = document.getElementById('mediaLibWindow');
        if (mediaWindow) {
            loadLibrary(1);
        }

        const mediaLibrary = document.getElementById('mediaLibrarySection');
        if (mediaLibrary) {
            const tpl = document.getElementById('media-file-tpl');
            if (tpl) {
                const tpl2 = tpl.content.cloneNode(true);
                const col = tpl2.querySelector('.col-sm-3');
                col.id = `file-${res.id}`;
                const imgLink = tpl2.querySelector('a[data-lightbox]');
                if (isImage(res.mimetype)) {
                    tpl2.querySelector('.img-box').style.background = `white url(${res.url}) center no-repeat`;
                    tpl2.querySelector('.img-box').style.backgroundSize = '80%';
                    imgLink.dataset.title = res.filename;
                    imgLink.href = res.url;
                    tpl2.querySelector('.fakeimg').alt = res.filename;
                    tpl2.querySelector('i[data-feather="file"]')
                        .remove();
                } else {
                    imgLink.remove();
                }
                tpl2.querySelector('.media-delete-button').dataset.fileId = res.id;
                tpl2.querySelector('button[data-clipboard-text]').dataset.clipboardText = res.url;
                tpl2.querySelector('figcaption p').title = res.filename;
                tpl2.querySelector('figcaption a').href = `/download/${res.id}`;
                tpl2.querySelector('figcaption a').textContent = `${res.filename}(${res.size})`;
                tpl2.querySelector('figcaption p small').textContent = res.created_at;

                const cols = mediaLibrary.querySelectorAll('.col-sm-3, .media-item');
                if (cols && cols.length > 0) {
                    mediaLibrary.insertBefore(tpl2, mediaLibrary.firstElementChild);
                } else {
                    mediaLibrary.appendChild(tpl2);
                }
                col.classList.add('animated', 'fadeDown');
                setTimeout(() => {
                    col.classList.remove('animated', 'fadeDown');
                }, 500);
            }
        }

        feather.replace();
        self.remove();
    });

    myDropzone.on('queuecomplete', () => {
        $('#fileuploadzone')
            .hide();
        if (document.querySelector('button[data-action="media-library-trigger"]')) {
            document.querySelector('button[data-action="media-library-trigger"]')
                .click();
        }
    });

    myDropzone.on('error', (file, error) => {
        showAlert('오류', `업로드 오류 : ${error.message}`, 0);
        console.log(file, error);
    });
}

$(document)
    .on('click', 'button.delete', (e) => {
        e.preventDefault();
        const id = e.currentTarget.dataset.fileId;
        const parent = e.currentTarget.closest('.file-row');
        deleteFile(parent, id);
    })
    .on('click', 'button.append', (e) => {
        e.preventDefault();
        const url = e.currentTarget.dataset.fileUrl;
        const mimeType = e.currentTarget.dataset.fileMime;
        const filename = e.currentTarget.dataset.fileName;
        let code = '';

        if (mimeType.split('/')[0] === 'image') {
            code = `<div class="text-center"><img src="${decodeURIComponent(`${url}`)}" class="img-fluid center-block" alt="${filename}"></div>`;
        } else {
            code = `<a href="/file/download/${url}">${filename}</a>`;
        }
        tinyMCE.execCommand('mceInsertContent', false, code);
    })
    .on('click', 'button.appendFeatured', function (e) {
        e.preventDefault();
        const url = `/storage/${$(this)
            .data('file-url')}`;
        const mimeType = $(this)
            .data('file-mime');

        if ($('#featuredImage').length === 1) {
            if (mimeType.split('/')[0] != 'image') {
                alert('이미지를 선택하세요.');
                return false;
            }
            $('#featuredImage')
                .val(url);
            $('#featuredPreview')
                .empty()
                .append(`<a href="${url}" data-lightbox="lightbox">`
                    + `<img src="${url}" class="img-fluid center-block">`
                    + '</a>');
        }
    })
    .on('focusin', (e) => {
        if ($(e.target)
            .closest('.mce-window').length) {
            e.stopImmediatePropagation();
        }
        if ($(e.target)
            .closest('.moxman-window').length) {
            e.stopImmediatePropagation();
        }
    });


window.makeSlug = function (string) {
    const regExp = /[\s\{\}\[\]\/?.,;:|\)*~`!^\_+<>@\#$%&\\\=\(\'\"]/gi;
    const result = string.replace(regExp, '-');
    const regExp2 = /\-$/gi;
    const final = result.replace(regExp2, '');

    return final.toLowerCase();
};

const datetimepickerConfig = {
    locale: 'ko',
    dayViewHeaderFormat: 'YYYY년 MM월',
    format: 'YYYY-MM-DD HH:mm:ss',
    sideBySide: true,
    icons: {
        time: 'datetime-icon datetime-icon-clock',
        date: 'datetime-icon datetime-icon-calendar',
        up: 'datetime-icon datetime-icon-arrow-up',
        down: 'datetime-icon datetime-icon-arrow-down',
        previous: 'datetime-icon datetime-icon-chevron-left',
        next: 'datetime-icon datetime-icon-chevron-right',
        today: 'datetime-icon datetime-icon-check',
        clear: 'datetime-icon datetime-icon-trash',
        close: 'datetime-icon datetime-icon-circle'
    }
};

const datetimepickerConfig2 = {
    locale: 'ko',
    dayViewHeaderFormat: 'YYYY년 MM월',
    format: 'YYYY-MM-DD',
    icons: {
        time: 'datetime-icon datetime-icon-clock',
        date: 'datetime-icon datetime-icon-calendar',
        up: 'datetime-icon datetime-icon-arrow-up',
        down: 'datetime-icon datetime-icon-arrow-down',
        previous: 'datetime-icon datetime-icon-chevron-left',
        next: 'datetime-icon datetime-icon-chevron-right',
        today: 'datetime-icon datetime-icon-check',
        clear: 'datetime-icon datetime-icon-trash',
        close: 'datetime-icon datetime-icon-circle'
    }
};

const dtConfig3 = {
    locale: 'ko',
    dayViewHeaderFormat: 'YYYY년 MM월',
    format: 'YYYY/MM/DD ddd'
};


$('.datepicker')
    .datetimepicker(dtConfig3);
$('.datepicker.dateonly')
    .datetimepicker({
        locale: 'ko',
        dayViewHeaderFormat: 'YYYY년 MM월',
        format: 'YYYY-MM-DD'
    });

$('.datetimepicker')
    .datetimepicker(datetimepickerConfig);
$('.datetimepicker-dateonly')
    .datetimepicker(datetimepickerConfig2);

/**
 * @return {string}
 */
window.FileSize = function (bytes, si) {
    const thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
        return `${bytes} B`;
    }
    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return `${bytes.toFixed(1)} ${units[u]}`;
};

window.beforeSubmitWarning = function (obj) {
    if (!confirm('이 글을 삭제할까요?')) return false;
    obj.submit();
};

window.makeFBtag = (response) => {
    const d = response.data;
    const code = [];
    const tg = document.querySelector('#facebook-feed .feed');
    for (const d of response.data) {
        if (d.message && d.type !== 'event') {
            const now = showDateKr(d.created_time);
            const message = d.message.length > 300 ? `${d.message.substring(0, 300)}...` : d.message;
            const html = `
            <div class="row">
                <div class="col-12 col-lg-4">
                    <div style="max-height:200px;overflow-y:hidden;" class="rounded">
                        <a href="https://facebook.com/'}${d.id}" target="_blank">
                            <img src="${d.full_picture}" class="img-fluid">
                        </a>
                    </div>
                </div>
                <div class="col-12 col-lg-8">
                    <p class="text-primary"><b>${now}</b></p>
                    <p><a href="${d.id}" target="_blank">${message}</a></p>
                </div>
            </div>`;

            code.push(html);
        }
    }
    tg.html(code.join(''));
};

window.makeFacebookEvents = (res) => {
    const d = res.data;
    const tg = document.querySelector('#facebook-event-feed .feed');
    const code = [];
    for (const d of res.data) {
        const html = `<div class="col-4">
                        <div class="card event">
                            <img src="'}${d.cover.source}" class="card-img-top" alt="">
                            <h5 class="card-header text-center">${d.name}</h5>
                            <div class="card-body">
                            <p class="card-text">${d.description}</p>
                            <ul class="list-unstyled">
                                <li><b>시작</b> : ${showDateKr(d.start_time)}</li>
                                <li><b>종료</b> : ${showDateKr(d.end_time)}</li>
                                <li><b>장소</b> : ${d.place.name}</li>
                            </ul>
                            </div>
                        </div>
                    </div>`;

        code.push(html);
    }
    tg.html(code.join(''));
};

$('.banner-carousel')
    .slick({
        autoplay: true,
        autoplaySpeed: 2000,
        arrow: false,
        dots: true,
        speed: 300,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                }
            }
        ]
    });

window.showResult = (tag, status) => {
    let tg = $('#series-contents-current .row');
    if (status === 0) {
        tg = $('#series-contents-old');
    }
    tg.html(tag);
};

window.loadSeriesSermons = async (page) => {
    const current_url = location.origin + location.pathname;
    try {
        const res = await axios.get(`/api/get/series?page=${page}`);
        const {
            per_page, current_page, last_page, first_page_url, last_page_url, next_page_url, prev_page_url, total, data
        } = res.data.series;

        const code = document.createDocumentFragment();
        for (const item of data) {
            const tpl = document.getElementById('series-item-tpl')
                .content
                .cloneNode(true);
            const {
                id, thumbnail, title, on_going
            } = item;

            tpl.querySelector('.card')
                .setAttribute('onclick', `location.href="${current_url}?cid=${id}"`);
            tpl.querySelector('.img-wrap').style.backgroundImage = `url(${thumbnail})`;
            tpl.querySelector('.series-title').innerText = title;
            tpl.querySelector('a').href = `${current_url}?cid=${id}`;

            if (!on_going) {
                tpl.querySelector('.ongoing')
                    .classList
                    .add('ended');
            }
            code.appendChild(tpl);
        }
        document.querySelector('#series-contents .row').innerHTML = '';
        document.querySelector('#series-contents .row')
            .appendChild(code);

        if (status === 0 && total > per_page) {
            const pagination = [
                `<li class="page-item"><a class="page-link" href="${first_page_url}&ongoing=0">처음</a></li>`,
                `<li class="page-item"><a class="page-link" href="${prev_page_url}&ongoing=0">이전</a></li>`
            ];

            for (let i = 1; i <= last_page; ++i) {
                const active = current_page === i ? 'active' : '';
                const pcode = `<li class="page-item${active}">`
                    + `<a class="page-link" href="{{ url()->current() }}?page=${i}&ongoing=0">${i}</a>`
                    + '</li>';
                pagination.push(pcode);
            }
            pagination.push(`<li class="page-item"><a class="page-link" href="${next_page_url}&ongoing=0">다음</a></li>`
                + `<li class="page-item"><a class="page-link" href="${last_page_url}&ongoing=0">마지막</a></li>`);

            const paginationWrap = document.getElementById('series-pagination');
            paginationWrap.querySelector('.pagination').innerHTML = pagination.join('');
            paginationWrap.style.display = '';

            feather.replace();
        }
    } catch (err) {
        console.log(err.response ? err.response : (err.request ? err.request : err));
        return false;
    }
};


$('#next-gen-wrap .photo-item')
    .hover(function () {
        anime({
            targets: this,
            duration: 30,
            scale: 1.2,
            easing: 'linear'
        });
    }, function () {
        anime({
            targets: this,
            duration: 30,
            scale: 1,
            easing: 'linear'
        });
    });

$('.list-thumbnail')
    .hover(function () {
        anime({
            targets: this,
            scale: 1.1
        });
    }, function () {
        anime({
            targets: this,
            scale: 1
        });
    });

window.publishToFacebook = function (obj) {
    const tg = obj.parentNode;
    const url = obj.dataset.fbPublish;
    tg.innerHTML = '<div class="spinner-grow spinner-grow-sm" role="status"><span class="sr-only">Loading...</span></div>';
    axios.post(url)
        .then((r) => {
            tg.innerHTML = `<a href="https://facebook.com/${r.data.fb_post_id}" target="_blank">보기</a>`;
        })
        .catch((err) => {
            if (err.response) {
                alert(err.response.data.message);
            } else {
                alert(err.message);
            }
            console.log(err);
        });
};

// DOM 삭제
window.removeFadeOut = (dom) => {
    dom.classList.add('animated', 'fadeOutRight');
    setTimeout(() => {
        dom.remove();
    }, 600);
};

window.fadeInDown = (dom) => {
    dom.classList.add('animated', 'fadeInDown');
    setTimeout(() => {
        dom.classList.remove('animated', 'fadeInDown');
    }, 1000);
};


// 헤더이미지 삭제
window.deleteHeaderBg = async (post_id) => {
    if (!confirm('헤더 배경 이미지를 삭제할까요?')) return false;

    const endpoint = `/api/delete-page-header-bg/${post_id}`;
    try {
        const res = await axios.delete(endpoint);
        const previewWrapper = document.querySelector('#header-bg-preview');
        previewWrapper.innerHTML = '';
    } catch (err) {
        console.log(err.response ? err.response : (err.request ? err.request : err));
        // showAlert('오류', '헤더 이미지 삭제 중 오류 발생');
    }
};

// 카테고리 불러오기
window.attachCategoryOption = async (ptype, cat_id = null) => {
    try {
        const res = await axios.get(`/admin/categories/get-category-by-post-type?post_type=${ptype}`);
        const code = ['<option value="">카테고리 선택...</option>'];
        for (const d of res.data) {
            code.push(`<option value="${d.id}">${d.name}</option>`);
        }
        document.getElementById('post-category-container').style.display = '';
        const postCategory = document.getElementById('post_category');
        postCategory.innerHTML = code.join('\n');
        if (cat_id) {
            postCategory.value = cat_id;
        }

    } catch (err) {
        showAlert('오류', '카테고리 로딩 중 오류 발생\n' + err.response?.data?.message ?? err.message);
    }
};


window.stripHtml = (html) => {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
};


// 회원가입
const registForm = document.getElementById('regist-form');
if (registForm) {

    $('#birth')
        .datetimepicker({
            locale: 'ko',
            format: 'YYYY-MM-DD',
            dayViewHeaderFormat: 'YYYY년 MM월'
        });

    registForm.addEventListener('submit', e => {
        e.preventDefault();

        const f = e.target;
        const fd = new FormData(f);

        Array.from(f.querySelectorAll('.is-invalid, .invalid-feedback'))
            .map(el=>{
                if (el.classList.contains('invalid-feedback')) {
                    el.textContent = '';
                } else {
                    el.classList.remove('is-invalid');
                }
            })

        axios.post(f.action, fd)
            .then(r => {
                alert('회원가입이 완료되었습니다.');
                location.href = '/';
            })
            .catch(err => {
                if (err.response && err.response.status === 422) {
                    const {errors} = err.response.data;
                    for(let key of Object.keys(errors)){
                        const msg = errors[key][0];
                        const el = f.querySelector(`[name="${key}"]`);
                        el.classList.add('is-invalid');
                        const feedback = el.closest('.form-group')
                            .querySelector('.invalid-feedback');
                        if (feedback) {
                            feedback.textContent = msg;
                        }
                    }

                    f.querySelector('.is-invalid')
                        .focus();
                } else if (err.response?.status === 404 || err.response?.status === 409 ) {
                    alert(err.response.data.message);
                    f.querySelector('#cert_no').focus();
                }
                else {
                    alert(`회원 가입 중 오류 발생\r\n${err.message}`);
                }
            });
    });
}

window.resendConfirmEmail = obj => {
    const {id} = obj.dataset;
    const endpoint = `/api/resend-confirm-email/${id}`;
    axios.post(endpoint)
        .then(r => {
            document.getElementById('regist-completed').style.display = 'hide';
            alert('메일을 발송했습니다.');
        })
        .catch(err => {
            console.log(err.response ? err.response : err);
            alert('오류 발생');
        });
};

const facilityContainer = document.querySelector('.facilities');
if (facilityContainer) {
    const photos = facilityContainer.querySelectorAll('.card-img-top');
    for (let item of photos) {
        const card = item.closest('.card');
        const link = document.createElement('a');
        link.classList.add('gallery');
        link.href = item.src;
        card.insertBefore(link, item);
        link.appendChild(item);
    }
    $('.facilities .card-deck').each(function () {
        $(this).magnificPopup({
            delegate: 'a',
            type: 'image',
            image: {
                titleSrc: function (item) {
                    return item.img[0].alt
                }
            },
            gallery: {
                enabled: true
            }
        })
    });
}


// 모바일 화면이 아닐 경우 메인네비게이션에서 첫번째 메뉴 클릭하면 첫번째 서브메뉴로 접근
if (document.body.getClientRects()[0].width > 480) {
    const mainNavigation = document.getElementById('main-navigation');
    if (mainNavigation) {
        mainNavigation.addEventListener('click', e => {
            let el = e.target;
            console.log(el, el.nextElementSibling);
            if (el.classList.contains('nav-link') && el.nextElementSibling && el.nextElementSibling.classList.contains('nav-child')) {
                location.href = el.nextElementSibling.querySelector('.nav-link').href;
            }
        })
    }
}


// 관리자에게 문의
const questionForm = document.getElementById('question-to-admin-form');
if ( questionForm ) {
    questionForm.addEventListener('submit', e=>{
        e.preventDefault();
        const f = e.target;
        const submitBtn = f.querySelector('button[type="submit"]');
        const fd = new FormData(f);

        submitBtn.setAttribute('disabled', true);
        submitBtn.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> 접수 중...';

        axios.post(f.getAttribute('action'), fd)
            .then(r=>{
                f.reset();
                submitBtn.removeAttribute('disabled');
                submitBtn.innerHTML = '저장하기';
                alert('문의가 접수되었습니다.');
            })
            .catch (err=>{
                console.log(err.response ? err.response : err);
                submitBtn.removeAttribute('disabled');
                submitBtn.innerHTML = '저장하기';

                if ( err.response?.status === 422 ) {
                    const {errors} = err.response.data;
                    for(let key of Object.keys(errors)) {
                        const el = f.querySelector(`[name="${key}"]`);
                        el.classList.add('is-invalid');
                        const feedback = el.closest('.form-group').querySelector('.invalid-feedback');
                        feedback.textContent = errors[key][0];
                    }

                    f.querySelector('.is-invalid').focus();

                } else {
                    alert('오류 발생! 관리자에게 문의바랍니다.');
                }
            })
    })
}
