window._ = require('lodash');
import Anime from 'animejs/lib/anime.es';
import 'animate.css/animate.css';
import {MultiDrag, Sortable} from 'sortablejs';
import WebFont from 'webfontloader';
import {DateTime} from 'luxon';
window.DateTime = DateTime;

window.anime = Anime;
window.Sortable = Sortable;
window.MultiDrag = MultiDrag;

window.$ = window.jQuery = require('jquery');
window.Tether = require('tether');
window.Popper = require('popper.js');
import 'bootstrap';

window.moment = require('moment');
moment.locale('ko');
require('dropzone');
require('pc-bootstrap4-datetimepicker'); // bootstrap datetime4
window.nestable = require('nestable2'); // nestable
window.feather = require('feather-icons'); // feathericon.com
window.tinymce = require('tinymce'); // tinymce
window.ClipboardJs = require('clipboard'); // clipboard
window.Plyr = require('plyr'); // html5 player
window.slick = require('slick-carousel');
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import interactionPlugin from '@fullcalendar/interaction';

window.Calendar = Calendar;
window.dayGridPlugin = dayGridPlugin;
window.timeGridPlugin = timeGridPlugin;
window.listPlugin = listPlugin;
window.bootstrapPlugin = bootstrapPlugin;
window.interactionPlugin = interactionPlugin;

import Swipe from 'swipejs';
window.Swipe = Swipe;

window.dayjs = require('dayjs');
require('dayjs/locale/ko')
dayjs.locale('ko');
let utc = require('dayjs/plugin/utc')
let timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found.');
}

const apiToken = document.head.querySelector('meta[name="api-token"]');
if (apiToken && apiToken.content.length > 0) {
    window.axios.defaults.headers.common['Authorization'] = `Bearer ${apiToken.content}`;
}

WebFont.load({
    google: {
        families: [
            'Lato:300,400,700'
        ],
        timeout: 2000
    },
    custom: {
        families: [
            'Noto Sans KR',
            'Noto Serif KR'
        ]
    }
});

$.extend(true, $.fn.datetimepicker.defaults, {
    dayViewHeaderFormat: 'YYYY년 MM월',
    locale: 'ko',
    timeZone: 'Asia/Seoul',
    icons: {
        time: 'datetime-icon datetime-icon-clock',
        date: 'datetime-icon datetime-icon-calendar',
        up: 'datetime-icon datetime-icon-arrow-up',
        down: 'datetime-icon datetime-icon-arrow-down',
        previous: 'datetime-icon datetime-icon-chevron-left',
        next: 'datetime-icon datetime-icon-chevron-right',
        today: 'datetime-icon datetime-icon-check',
        clear: 'datetime-icon datetime-icon-trash',
        close: 'datetime-icon datetime-icon-circle'
    }
});

require('magnific-popup/dist/jquery.magnific-popup');

if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector ||
        Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
    Element.prototype.closest = function(s) {
        var el = this;

        do {
            if (el.matches(s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };
}

window.animateCss = (element, animationName, callback) => {
    const node = document.querySelector(element);
    node.classList.add('animated', animationName);

    function handleAnimationEnd() {
        node.classList.remove('animated', animationName);
        node.removeEventListener('animationend', handleAnimationEnd);

        if (typeof callback === 'function') callback();
    }

    node.addEventListener('animationend', handleAnimationEnd);
};

$('#flash-overlay-modal').modal();

window.setCookie = (cookieName, cookieValue, cookieExpire, cookiePath, cookieDomain, cookieSecure) => {
    let cookieText=escape(cookieName)+'='+escape(cookieValue);
    cookieText+=(cookieExpire ? '; EXPIRES='+cookieExpire.toGMTString() : '');
    cookieText+=(cookiePath ? '; PATH='+cookiePath : '');
    cookieText+=(cookieDomain ? '; DOMAIN='+cookieDomain : '');
    cookieText+=(cookieSecure ? '; SECURE' : '');
    document.cookie=cookieText;
}

window.getCookie = cookieName => {
    let cookieValue=null;
    if(document.cookie){
        var array=document.cookie.split((escape(cookieName)+'='));
        if(array.length >= 2){
            var arraySub=array[1].split(';');
            cookieValue=unescape(arraySub[0]);
        }
    }
    return cookieValue;
}

window.deleteCookie = cookieName => {
    const temp=getCookie(cookieName);
    if(temp){
        setCookie(cookieName,temp,(new Date(1)));
    }
}

window.replaceClass = function (obj, classFrom, classTo) {
    const t = $(obj);
    if (t.hasClass(classFrom)) {
        t.removeClass(classFrom)
            .addClass(classTo);
    }
};

window.nl2br = (str, replaceMode, isXhtml) => {
    let breakTag = (isXhtml) ? '<br />' : '<br>';
    let replaceStr = (replaceMode) ? '$1'+ breakTag : '$1'+ breakTag +'$2';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
};

window.br2nl = (str, replaceMode) => {

    let replaceStr = (replaceMode) ? "\n" : '';
    // Includes <br>, <BR>, <br />, </br>
    return str.replace(/<\s*\/?br\s*[\/]?>/gi, replaceStr);
}

window.stripTag = (str) => str.replace(/(<([^>]+)>)/ig, '');

window.removeNl = (str) => str.replace(/\s\n/gi, '');

window.showDateKr = (str) => {
    const dt = new Date(str);

     return dt.getFullYear() + '년'  +
         (dt.getMonth() + 1) + '월 ' +
         dt.getDate() + '일 ' +
         dt.getHours() + '시 ' +
         dt.getMinutes() + '분';
};
